@import '../phs-config';
@import '../utils/utils';
@import 'node_modules/foundation-sites/scss/components/button';

$button-base: (
  padding: 8px 18px,
  border-radius: 100px,
  font-size: 16px,
  line-height: 22px,
  height: 40px,
);

$button_360: (
  padding: 13px,
  border-radius: 10px,
  font-size: 18px
);

$button_1366: (
  padding: 13px,
  border-radius: 10px,
  font-size: 16px
);

$button_1440: (
  padding: 15px,
  border-radius: 10px,
  font-size: 16px
);

$button_1920: (
  padding: 15px,
  border-radius: 10px,
  font-size: 21px
);

@mixin buttons() {

  .phs-button,
  input[type='submit'].hs-button {
    @include button-base;
    @include addTransition(transform, 0.5s, ease-in-out);
    height: map-get($button-base, height);
    margin: map-get($button-base, margin);
    padding: rem-calc(map-get($button-base, padding));
    border-radius: rem-calc(map-get($button-base, border-radius));
    font-size: rem-calc(map-get($button-base, font-size));
    text-decoration: none;
    font-weight: bold;
    outline: none;

    @each $color in $phs-colors {
      &.#{$color} {
        @include button-style(map-get($foundation-palette, $color), map-get($foundation-palette, $color));

        &.disabled,
        &[disabled] {
          @include button-disabled($interdark-gray);
          pointer-events: none;
          opacity: 1 !important;
        }

        &.hollow {
          position: relative;
          background: transparent;
          color: map-get($foundation-palette, $color);
          border: 1px solid map-get($foundation-palette, $color);
          outline: none;
          border-radius: map-get($button-base, border-radius);
          padding: rem-calc(map-get($button-base, padding));

          &.disabled,
          &[disabled] {
            @include button-disabled($interdark-gray);
            pointer-events: none;
            opacity: 1 !important;
          }
        }
      }
    }

    @include breakpoint(medium) {
      width: 35%;
    }
  }

  button,
  input[type='button'] {
    cursor: pointer;
  }
}

@mixin phs-buttons {
  @include buttons();
}