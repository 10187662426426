@import '../phs-config';
@import '../utils/utils';

@mixin phs-menu {
  .siteLogo {
    padding: rem-calc(1px);
  }

  .phs-menu-service {
    @include breakpoint(large up) {
      text-align: center;
      padding: rem-calc(12px) 0 rem-calc(16px);

      & .medium-auto.tc {
        z-index: 2;
      }

    }
    @include breakpoint(medium down) {
      display: grid;
      grid-template-columns: repeat(3, 33%);
    }
    padding: rem-calc(12px);
    z-index: 99999999999;

  }
}
