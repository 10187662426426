@mixin ErrorGeneric {
  background: transparent;
  .error-image {
    @include breakpoint(medium) {
      height: rem-calc(300px) !important;
    }
    @include breakpoint(large up) {
      height: rem-calc(400px) !important;
    }
  }
  .food-product-error-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h1,
    p {
      margin: 0;
    }

    h1 {
      margin-top: rem-calc(24px);
      margin-bottom: rem-calc(16px);
    }

    p {
      max-width: rem-calc(400px);
    }
    .food-product-error-image {
      @include breakpoint(medium) {
        height: rem-calc(150px) !important;
      }
      @include breakpoint(large up) {
        height: rem-calc(200px) !important;
      }
    }
  }
}
