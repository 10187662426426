@mixin EmptyCart {
  .cart-empty {
    max-width: $global-width-lg;
    margin: auto;
  }
  .thin-title {
    font-weight: 400;
  }
  .flex-display {
    display: flex;
  }
  .title-cart {
    font-size: rem-calc(24px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }
  .subtitle-format {
    font-size: rem-calc(24px);
    line-height: 1.25;
    letter-spacing: rem-calc(0.15px);
  }
  .button-empty-cart {
    font-size: rem-calc(16px);
    width: rem-calc(142px);
    height: rem-calc(50px);
  }
  .justify-center {
    justify-content: center;
  }

  .qr_store_image {
    width: 83px;
    height: 83px;
    margin-left: 27px;
  }
}
