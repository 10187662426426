@mixin OrderSuccess {
  #order-success {
    margin-top: rem-calc(100px);

    .success_logo {
      max-height: rem-calc(135px);

      @include breakpoint(medium down) {
        max-height: rem-calc(135px);
      }
    }

    .position-logo {
      position: relative;
      bottom: 0;
    }

    .position-logo-badge {
      position: relative;
      bottom: 0;
      margin: 0 5px 5px 0;
    }

    #right-column {
      h2.top-title {
        margin-bottom: rem-calc(32px);
        position: relative;

        font-size: rem-calc(48px);
        font-weight: 900;
        line-height: rem-calc(24px);
        text-align: center;
        color: #eb5d0b;
        font-family: Work Sans;

        @include breakpoint(medium down) {
          top: rem-calc(18px);
        }
      }

      h1 {
        margin-top: rem-calc(44px);
        margin-bottom: rem-calc(8px);
      }

      display: grid;
      z-index: 5;

      div.sub-title {
        font-size: rem-calc(14px);
        color: $black;
        margin: auto;
        width: rem-calc(248px);
        position: relative;
        top: -20px;
      }
    }

    button {
      margin-bottom: rem-calc(10px) !important;
      width: rem-calc(295px);
      overflow: hidden;
      white-space: nowrap;

      @include breakpoint(medium down) {
        margin-bottom: 0 !important;
      }
    }

    .button-container {
      padding-top: rem-calc(10px);
      padding-bottom: rem-calc(10px);

      .hollow {
        background-color: $white;
      }

      @include breakpoint(medium down) {
        position: fixed;
        bottom: 0px;
        left: 0px;
        border-radius: 0 0 0 0;
        margin: 0px;
        width: 100%;
        z-index: 100;
      }
    }
  }

  button.phs-button.secondary.font-formatte,
  button.phs-button.secondary.hollow.font-formatted-nobg {
    font-size: rem-calc(16px);
  }

  .invite-friends-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff !important;
    color: #00a2ab !important;
    border: 1px solid #00a2ab !important;
    max-width: 200px;

    @include breakpoint(medium down) {
      background: transparent !important;
      color: #00a2ab !important;
      border: 0px solid transparent !important;
    }
  }

  .sub-title.sub-title-format {
    font-weight: 500 !important;
  }

  .title-format {
    font-size: rem-calc(20px);
  }

  .bottom-bg {
    position: absolute;
    left: 0px;
    right: 0px;
    width: 100vw;
    top: 27rem;
    height: calc(100% - 27rem);
    background-color: #e3fffe;
    z-index: -1;

    @include breakpoint(medium down) {
      top: 22rem;
      height: calc(100% - 22rem);
    }
  }

  .bottom-background {
    position: relative;
    height: 100%;
    width: 100vw;
    left: 50%;
    right: 50%;
    bottom: -1.25rem;
    margin-left: -50vw;
    margin-right: -50vw;
    background-color: #b0e2e5;

    @include breakpoint(medium down) {
      background-color: #e6f6f7;
    }

    &-item-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      gap: 24px;
      @include breakpoint(medium down) {
        flex-direction: column;
      }
    }

    &-item-text {
      text-align: left;
      max-width: 280px;

      @include breakpoint(medium down) {
        text-align: center;
      }
    }

    @include breakpoint(medium down) {
      position: fixed;
      height: auto;
      bottom: 0;
    }

    & > div {
      margin: 0 auto;
      padding: 1.4rem;

      bottom: 0;
      @include breakpoint(medium down) {
        gap: rem-calc(12px);
      }
    }

    .hollow {
      background-color: $white;
    }
  }

  .desktop-download-app-box {
    display: flex;
    justify-content: center;
    column-gap: 30px;

    .mobile-store-badges {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;

      & > a {
        display: block;
      }
    }

    .qr-box {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .qr-code {
        width: 83px;
        height: 83px;
      }

      & > p {
        display: block;
        margin: 0;
        font-weight: 700;
      }
    }
  }

  .margin-bottom-page-correction {
    pointer-events: none;
    margin-bottom: rem-calc(-21px);
  }

  .wrapper-confetti {
    position: absolute;
    width: 100%;
    max-width: rem-calc(800px);
    top: 150px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .back_home {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &__link {
      font-family: Lato;
      font-size: rem-calc(16px);
      font-weight: 700;
      line-height: rem-calc(22px);
      text-align: center;
      color: #fff !important;
      background: #00a2ab;
      padding: rem-calc(12px) rem-calc(20px);
      border-radius: 100px;
      box-shadow: 2px 2px 8px 0px rgba(0, 162, 171, 0.25);

      &:hover {
        background: #007379;
      }
    }
  }

  .additional-info-container {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: rem-calc(32px);
    margin: rem-calc(72px) 0;
    align-items: flex-start;

    @include breakpoint(small down) {
      flex-direction: column;
      gap: rem-calc(24px);
      width: rem-calc(270px);
      margin: rem-calc(72px) auto;
    }

    .separator {
      width: 1px;
      height: 100%;
      background: #000000;

      @include breakpoint(xsmall down) {
        width: 100%;
        height: 1px;
        margin: 0 auto;
        background: #eaeaea;
      }
    }
  }

  .additional-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: rem-calc(8px);
    min-width: rem-calc(150px);

    &.billing-info-col {
      height: 100%;
      justify-content: space-between;
      max-width: rem-calc(270px);
    }

    @include breakpoint(xsmall down) {
      width: 100%;
    }

    &-title {
      font-size: rem-calc(14px);
      font-weight: 700;
      line-height: rem-calc(18px);
      text-align: center;
      color: #000000;
      text-align: left;
      margin: 0;
    }
    &-text {
      font-size: rem-calc(14px);
      font-weight: 400;
      line-height: rem-calc(18px);
      text-align: center;
      color: #000000;
      text-align: left;
      margin: 0;

      &.shipping-type {
        text-transform: capitalize;
      }

      &.billing-info {
        max-width: rem-calc(270px);
        margin-top: rem-calc(8px);
        text-wrap: balance;
      }
    }
    &-link {
      display: flex;
      align-items: center;
      gap: rem-calc(2px);
      color: #00a2ab;
      font-size: rem-calc(14px);
      font-weight: 700;
      text-align: left;
      margin-top: rem-calc(4px);
      cursor: pointer;
      &-icon {
        width: rem-calc(18px);
        height: rem-calc(18px);
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
