@mixin StoreMap {
  .shipping-store-map {
    opacity: 1;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -ms-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
    display: inline-block;
    width: 100%;
    text-align: left;
    @include breakpoint(medium down) {
      display: inline-block;
    }
    #maps-container {
      display: inline-block;
      height: 90%;
      width: 100%;
      @include breakpoint(medium down) {
        height: calc(80% + -50px);
        width: 100%;
      }
    }
    #maps-observer {
      float: left;
      height: 100%;
      width: 100%;
      transition: width 0.3s;
      @include breakpoint(medium down) {
        width: 100%;
        display: inline-block;
      }
    }
    #maps-marker-summary {
      overflow-x: hidden;
      overflow-y: auto;
      width: 0;
      float: left;
      transition: width 0.3s;
      @include breakpoint(medium down) {
        width: 100%;
        display: none;
        overflow-x: none;
        overflow-y: none;
        height: auto;
      }
    }
    &.concealed {
      opacity: 0;
    }
    &.opened {
      @include breakpoint(medium down) {
        height: 100%;
      }
      #maps-observer {
        height: 100%;
        width: 71%;

        @include breakpoint(medium down) {
          width: 100%;
          display: none;
        }
      }
      #maps-marker-summary {
        width: 29%;
        @include breakpoint(medium down) {
          width: 100%;
          display: inline-block;
          margin: rem-calc(10px) 0;
          overflow-y: scroll !important;
        }
      }
    }
    .markerCard {
      .title {
        font-weight: bold;
        font-size: rem-calc(16px);
        line-height: 1.38;
        color: $black;
        display: inline-block;
        overflow: hidden;
        width: rem-calc(200px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .phs-button {
        margin-left: auto !important;
        margin-right: auto !important;
        margin-top: rem-calc(10px) !important;
        margin-bottom: rem-calc(10px) !important;
        background-color: $white;
        border: rem-calc(1px) solid map-get($foundation-palette, secondary);
        color: map-get($foundation-palette, secondary);
        position: absolute;
        top: rem-calc(83px);
        right: rem-calc(9px);
      }
      .button-container {
        display: inline-block;
        text-align: right;
        align-content: center;
        width: 100%;
        padding-right: rem-calc(12px);
      }
      &.selected {
        box-shadow: 0 rem-calc(4px) rem-calc(16px) 0 rgba(0, 62, 171, 0.3);
      }
      &.selected button {
        background-color: map-get($foundation-palette, secondary);
        color: $white;
      }
      position: relative;
      padding-left: 0.625rem;
      margin-bottom: rem-calc(8px);
      margin-left: rem-calc(13px);
      width: 95%;
      height: auto;
      height: rem-calc(154px);
      border-radius: rem-calc(10px);
      box-shadow: 0 rem-calc(4px) rem-calc(16px) 0 rgba(0, 0, 0, 0.1);
      @include breakpoint(large down) {
        width: 94%;
        margin-left: rem-calc(10px);
        margin-bottom: rem-calc(9px);
        margin-top: rem-calc(9px);
      }
    }
    #autocomplete-places {
      display: inline-block;
      margin-bottom: rem-calc(10px) !important;
      border: rem-calc(1px) solid $interdark-gray;
      padding: rem-calc(10px);
      min-width: rem-calc(200px);
      margin-bottom: rem-calc(30px);
      width: 50%;
      border-radius: rem-calc(40px);
      box-shadow: 0 rem-calc(4px) rem-calc(16px) 0 rgba(0, 0, 0, 0.1);
      font-size: rem-calc(14px);
      width: rem-calc(590px);
      height: rem-calc(32px);
      @include breakpoint(medium down) {
        background-color: $light-gray;
        border-radius: rem-calc(50px);
        width: 100%;
        margin-left: 0;
      }
    }
    #maps-search-button {
      @include breakpoint(medium down) {
        display: none;
      }

      border-radius: rem-calc(10px);
      color: $white;
      padding: rem-calc(10px);
      padding-right: rem-calc(30px);
      img {
        position: relative;
        bottom: rem-calc(1px);
        right: rem-calc(40px);
      }
    }
    .distance_label {
      float: right;
      position: absolute;
      top: 0;
      right: rem-calc(-2px);
      padding-top: 0.625rem;
      padding-right: 0.625rem;
      bottom: rem-calc(56px);
      font-size: rem-calc(14px);
      color: #888888;
    }
    .store-map-search-area {
      display: inline;
    }
    .legend {
      margin-bottom: rem-calc(20px);
      position: relative;
      right: 13px;
      @include breakpoint(medium down) {
        display: none;
      }
      float: right;
      .shop_icon {
        padding-top: rem-calc(13px);
        float: left;
        height: rem-calc(30px);
        img {
          padding: rem-calc(5px);
          height: rem-calc(30px);
        }
      }
    }
    &.opened {
      #switch_between_map_and_shop {
        .shop {
          color: $white;
          background-color: map-get($foundation-palette, secondary);
        }
        .maps {
          color: map-get($foundation-palette, secondary);
          background-color: $white;
        }
      }
    }
    #switch_between_map_and_shop {
      display: grid;
      grid-auto-rows: rem-calc(50px);
      grid-template-columns: repeat(2, 50%);
      margin-top: rem-calc(9px);
      margin-bottom: rem-calc(9px);
      .shop {
        font-weight: bold;
        border: rem-calc(1px) solid map-get($foundation-palette, secondary);
        border-radius: rem-calc(50px) 0px 0px rem-calc(50px);
        color: map-get($foundation-palette, secondary);
        background-color: $white;
        padding: rem-calc(15px);
      }
      .maps {
        font-weight: bold;
        border: rem-calc(1px) solid map-get($foundation-palette, secondary);
        border-radius: 0px rem-calc(50px) rem-calc(50px) 0px;
        color: $white;
        background-color: map-get($foundation-palette, secondary);
        padding: rem-calc(15px);
      }
    }
    .title {
      font-weight: bold;
      color: map-get($foundation-palette, primary);
    }
  }
  .store-not-found-near-you {
    margin: auto;
  }
  .mappa {
    height: rem-calc(480px);
    border-radius: rem-calc(10px) !important;
    @include breakpoint(medium down) {
      height: rem-calc(400px);
    }
  }
  .bg-orange {
    width: rem-calc(110px);
    padding: rem-calc(4px) rem-calc(10px) rem-calc(5px);
    height: rem-calc(24px);
    border-radius: rem-calc(40px);
    background-color: #ffd1b5;
    font-size: rem-calc(12px);
    font-weight: 600;
    color: map-get($foundation-palette, primary);
  }
  .bg-grey {
    width: rem-calc(110px);
    padding: rem-calc(4px) rem-calc(10px) rem-calc(5px);
    height: rem-calc(24px);
    border-radius: rem-calc(40px);
    background-color: #d2d5db;
    font-size: rem-calc(12px);
    font-weight: 600;
    color: #9b9b9b;
  }
  .position-address {
    position: relative;
    top: rem-calc(26px);
    font-size: rem-calc(14px);
    font-weight: 500;
    color: $black;
  }
  .pos-relative {
    position: relative;
    top: rem-calc(15px);
  }
  .pos-relative-t {
    position: relative;
    top: rem-calc(9px);
  }
  .mrg-right img {
    margin-right: rem-calc(3px);
  }
  // .markerCard.selected::before {
  //   content: 'Il tuo negozio selezionato \2713';
  //   position: absolute;
  //   bottom: rem-calc(530px);
  //   font-size: rem-calc(14px);
  //   font-weight: 500;
  //   line-height: 1.43;
  //   color: map-get($foundation-palette, secondary);
  //   @include breakpoint(medium down) {
  //     bottom: rem-calc(515px);
  //   }
  // }
}
