@import './generic-form.scss';

@mixin ShippingMailOrder {
  .google-address-open-modal {
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

    button {
      display: flex;
      align-items: center;
      height: rem-calc(40px);
      padding: rem-calc(12px);
      border-radius: rem-calc(40px);
      background-color: $white;
      margin-bottom: rem-calc(20px);
      color: $dark-gray;
      border: 1px solid #d2d5db;
      box-shadow: 0 0.25rem 1rem 0 rgba(0, 0, 0, 0.1);

      @include breakpoint(medium down) {
        margin: 0 rem-calc(10px);
      }
    }

    .text {
      width: 100%;
      text-align: center;
    }
  }

  .request-invoice-banner {
    display: flex;
    gap: rem-calc(8px);
    margin: rem-calc(20px) 0;
    background-color: $light-gray;
    padding: rem-calc(16px) rem-calc(24px);
    border-radius: rem-calc(16px);
    width: fit-content;
    text-align: start;

    &__description {
      display: flex;
    }
  }

  .address-form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    @include breakpoint(small down) {
      grid-template-columns: 1fr;
    }
    width: 100%;
    max-width: 100% !important;

    .d-row {
      margin-bottom: rem-calc(10px);
    }

    select {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      color: $black !important;
    }

    .error-container {
      min-height: rem-calc(20px);
    }

    .error-input {
      border: solid rem-calc(1px) #ff3b3b;
      background-color: #fdcfcf;
      font-size: rem-calc(12px);
      line-height: 1.33;
      color: #ff3b3b;
      margin-right: rem-calc(20px);
      @include breakpoint(medium down) {
        margin-right: rem-calc(0px);
      }
    }

    .align-label {
      text-align: left;
      @include breakpoint(medium down) {
        text-align: center;
      }
      @include breakpoint(small down) {
        margin: auto;
        width: 16.25rem;
        text-align: left;
      }
    }

    .align-center {
      text-align: left;
      display: flex;
      justify-content: space-around;
      align-items: center;
      @include breakpoint(medium down) {
        text-align: center;
      }
      @include breakpoint(small down) {
        margin: auto;
        width: 16.25rem;
        text-align: center;
      }
    }
    &.column {
      grid-template-columns: 1fr;
      width: 30rem;
      gap: 1rem;

      input {
        width: 100%;
      }
    }
  }

  .shipping-mail-order-top-level {
    margin: rem-calc(30px);
    @include breakpoint(medium down) {
      margin: rem-calc(10px);
    }

    .mail-order-address-container {
      margin: auto;
      @include breakpoint(small down) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .first-block {
        width: 100%;
        @include breakpoint(large up) {
          display: flex;
        }
        @include breakpoint(small down) {
          display: flex;
          justify-content: center;
          margin-bottom: rem-calc(20px);
        }
      }

      .my-address {
        label.card-group-title {
          font-size: rem-calc(14px);
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          color: map-get($foundation-palette, secondary) !important;
        }

        .circle {
          display: inline-block;
          width: rem-calc(15px);
          height: rem-calc(15px);
          margin-left: rem-calc(6px);
        }
      }

      .my-address,
      .others-address {
        text-align: left;

        label.card-group-title {
          margin-left: rem-calc(20px);
          font-size: rem-calc(14px);
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          color: $dark-gray;
        }
      }

      .others-address {
        margin-bottom: rem-calc(20px);
      }
    }

    .button-area {
      @include breakpoint(medium down) {
        display: flex;
        flex-flow: column;
        position: fixed;
        bottom: 0px;
        left: 0px;
        border-radius: 0 0 0 0;
        margin: 0px;
        width: 100%;
        z-index: 100;
        background-color: $light-gray;
        padding-top: rem-calc(20px);
        padding-left: rem-calc(20px);
        padding-right: rem-calc(20px);
        button {
          margin: auto !important;
          margin-bottom: rem-calc(15px) !important;
          width: 100% !important;
        }
        #continue {
          order: 0;
        }
        #add {
          order: 1;
        }
      }
      margin-top: rem-calc(10px);
      margin-bottom: rem-calc(10px);
    }

    #top-area {
      width: rem-calc(260px);
      right: -5rem;
      @include breakpoint(medium down) {
        position: fixed;
        bottom: 0px;
        left: 0px;
        border-radius: 0 0 0 0;
        margin: 0px;
        width: 100%;
        z-index: 100;
      }

      button {
        width: 100%;
        @include breakpoint(medium up) {
          margin-bottom: rem-calc(10px) !important;
        }
      }

      height: fit-content;
      @include breakpoint(medium down) {
        height: auto;
      }
      position: relative;
      right: -5rem;
      margin-top: rem-calc(30px);
      margin-left: rem-calc(20px);
      padding: rem-calc(15px);
      width: rem-calc(260px);
      border-radius: rem-calc(24px);
      background-color: $light-gray;
    }
  }
  .shipping-mail-order-address-edit {
    @include breakpoint(medium down) {
      .address-form .d-row {
        flex-direction: column;

        input,
        select {
          //margin-left: auto;
          margin-right: auto;
        }
      }
    }
    @include GenericForm;
    text-align: center;

    textarea,
    input,
    select {
      width: 100%;
      padding: rem-calc(10px);
      margin-top: rem-calc(10px);
      border: 1px solid $interdark-gray;
      border-radius: rem-calc(3px);
      display: flex;
    }

    textarea {
      height: rem-calc(170px);
    }

    .error {
      border-color: red !important;
    }

    .valid {
      border-color: map-get($foundation-palette, primary);
    }

    button[disabled] {
      pointer-events: none;
      opacity: 0.65;
      background-color: $interdark-gray !important;
    }

    button[name='submit'] {
      @include breakpoint(medium up) {
        margin-left: rem-calc(10px);
      }
    }
  }
  .shipping-mail-order {
    text-align: center;

    .invalid-address-title {
      color: map-get($foundation-palette, warning);
      position: relative;
      top: rem-calc(14px);
    }

    .card-container {
      display: grid !important;
      grid-template-columns: repeat(2, 50%);
      @include breakpoint(medium down) {
        grid-template-columns: repeat(1, 100%);
        grid-gap: rem-calc(20px);
      }
    }

    .no-address-warning {
      display: block !important;
      margin-left: auto;
      margin-right: auto;
      margin-top: rem-calc(20px);
      margin-bottom: rem-calc(40px);
    }

    .shipping-address-item.selected {
      border: none;
      box-shadow: 0 rem-calc(4px) rem-calc(16px) 0 rgba(0, 62, 171, 0.3);
      position: relative;
      top: 0px;
      left: 0px;

      .editing {
        border-top: none;
      }

      .selcted_address_label {
        display: inline-block;
      }
    }

    .shipping-address-item {
      position: relative;
      top: rem-calc(1px);
      left: rem-calc(1px);
      @include breakpoint(medium down) {
        margin: auto;
        width: rem-calc(300px);
      }

      .rounded-radio {
        float: right;
      }

      text-align: left;
      border: none;
      border-radius: rem-calc(10px);
      width: rem-calc(350px);
      min-height: rem-calc(170px);
      margin: rem-calc(10px);
      box-shadow: 0 rem-calc(4px) rem-calc(16px) 0 rgba(0, 0, 0, 0.1);

      .address {
        padding: rem-calc(24px);
        min-height: rem-calc(130px);
      }

      .editing {
        border-top: none;

        table {
          width: 100%;

          td {
            a {
              display: block;
            }

            padding-top: rem-calc(5px);
            padding-bottom: rem-calc(10px);
            padding-left: rem-calc(24px);
            padding-right: rem-calc(10px);
            @include breakpoint(medium down) {
              padding-left: 0;
            }
            width: 50%;

            &:first-child {
              text-align: left;
            }

            &:last-child {
              text-align: right;
            }
          }
        }

        .edit-item {
          float: left;
          cursor: pointer !important;
          margin-left: auto;

          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            img {
              padding-left: rem-calc(10px);
            }
          }
        }

        .remove-item {
          float: right;
          cursor: pointer !important;
          margin-left: auto;
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            img {
              padding-left: rem-calc(10px);
            }
          }
        }
      }

      .selcted_address_label {
        display: none;
      }

      .choose-button {
        float: right;
      }
    }
  }
  .name-style {
    position: relative;
    font-size: rem-calc(16px);
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
  }
  .careof-style {
    position: relative;
    display: block;
  }
  .styled-card {
    font-size: rem-calc(14px);
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    position: relative;
    bottom: rem-calc(10px);
  }
  td.remove-item-pad {
    position: relative;
    right: rem-calc(90px);
  }
  button.f-size {
    font-size: rem-calc(16px);
  }
  button.phs-button.secondary.hollow.bg-white {
    background-color: $white;
  }
}
