@mixin PageHeader {
  .page-header {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);

    @include breakpoint(large up) {
      #back-button,
      #title {
        display: none;
      }
      #logo {
        img {
          height: rem-calc(32px);
        }
      }
    }
    @include breakpoint(medium down) {
      flex-direction: column;
      flex-grow: 3;
      justify-content: space-evenly;
      #back-button {
        color: map-get($foundation-palette, secondary);
        font-size: rem-calc(13px);
        .back-button-content {
          display: none;
          margin: rem-calc(5px);
          img {
            width: rem-calc(8px);
            height: rem-calc(13.7px);
            margin-right: rem-calc(5px);
          }
        }
      }
      #title {
        font-size: rem-calc(18px);
        text-align: center;
        font-weight: bold;
        color: $black;
      }
      #logo {
        text-align: right;
        align-self: baseline;
        img {
          height: rem-calc(18px);
          margin-bottom: rem-calc(2px);
        }
      }
    }
  }
}
