@import '../phs-config';
@import '../utils/utils';

@mixin tbodySizeDefault {
  tr {
    td,
    th {
      padding: rem-calc(20px);
    }
    @include breakpoint(medium down) {
      td {
        &:nth-child(2) {
          width: 100%;
          text-align: left;
        }
      }
    }
    @include breakpoint(medium down) {
      .col-3 {
        position: relative;
      }
      .col-4,
      .col-5,
      .col-6 {
        display: none;
      }
    }
    @include breakpoint(large up) {
      td {
        &.col-1 {
          width: rem-calc(60px);
        }
        &.col-2 {
          min-width: rem-calc(300px);
          text-align: left;
        }
        &.col-3 {
          min-width: rem-calc(100px);
          text-align: left;
        }
        &.col-4 {
          min-width: rem-calc(100px);
          text-align: center;
          position: relative;
        }
        &.col-5 {
          width: rem-calc(100px);
          text-align: center;
          padding: rem-calc(20px) rem-calc(20px) rem-calc(20px) 0;
          color: map-get($foundation-palette, secondary);
        }
        &.col-6 {
          width: rem-calc(100px);
          text-align: center;
          padding: rem-calc(20px) rem-calc(20px) rem-calc(20px) 0;
          color: map-get($foundation-palette, secondary);
        }
      }
    }
  }
}

.non-cumulative-modal-trigger {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  top: 0%;
  left: 0%;
  min-width: 18rem;
  text-align: left;
  padding: 0 rem-calc(20px);
  height: 100%;
  gap: rem-calc(4px);

  p {
    color: #eb5d0b;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: rem-calc(4px);
  }
}

.mobile-cumulative-modal-trigger {
  display: none;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  @include breakpoint(medium down) {
    display: block;
  }
}
.promotion-dialog {
  width: auto !important;
  height: auto !important;
  padding: rem-calc(40px) rem-calc(24px) !important;
  @include breakpoint(medium down) {
    max-width: rem-calc(304px);
  }
}
.promotion-modal-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: rem-calc(304px);
  * {
    margin: 0;
  }

  h2 {
    margin-top: rem-calc(32px);
    margin-bottom: rem-calc(16px);
    font-size: rem-calc(24px);
    line-height: rem-calc(28px);
  }

  p {
    line-height: rem-calc(24px);
  }
}

table.order-items-table.shadow-shipping-cost {
  box-shadow: 0 rem-calc(4px) rem-calc(16px) 0 rgba(0, 0, 0, 0.1);
}

@mixin ShoppingCartSummary {
  .total-box {
    margin-bottom: rem-calc(16px);
  }
  #total-preview,
  .total-preview {
    font-size: rem-calc(13px);
    font-weight: bold;
    margin-bottom: rem-calc(13px);
  }
  .proceed-button {
    text-align: center;
    font-size: rem-calc(14px);
    @include breakpoint(medium down) {
      text-align: center;
      .total-price {
        float: right;
        padding: 0px;
        margin: 0px !important;
        vertical-align: middle;
      }
    }
  }
  .proceed-button:disabled {
    pointer-events: none;
    background-color: $interdark-gray !important;
  }

  .proceed-button.disabled {
    pointer-events: none;
    background-color: $interdark-gray !important;
  }
  .add-products {
    font-size: rem-calc(14px);
  }
  @include breakpoint(medium down) {
    padding: 0px;
  }
  .image-icon {
    cursor: pointer;
    width: auto;
    height: rem-calc(16px);
    @include breakpoint(medium down) {
      margin-left: rem-calc(23px);
    }
    @include breakpoint(small down) {
      margin-left: 0;
    }
  }
  #cart-header {
    @include breakpoint(medium down) {
      margin: rem-calc(20px);
    }
    margin: rem-calc(60px);
  }
  #cart-progress {
    max-width: rem-calc(370px);
    @include breakpoint(medium down) {
      display: none;
      visibility: hidden;
    }
    width: fit-content;
    margin: auto;
  }
  #cart-progress-label {
    margin: auto;
    max-width: rem-calc(430px);
    @include breakpoint(medium down) {
      display: none;
      visibility: hidden;
    }
    width: fit-content;
    margin: auto;
    margin-top: rem-calc(10px);
    margin-bottom: rem-calc(10px);
    .label {
      width: rem-calc(110px);
      color: $interdark-gray;
      font-size: rem-calc(14px);
    }
    .label.active {
      color: map-get($foundation-palette, secondary) !important;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    .label.old {
      color: $dark-gray;
    }
  }
  .phs-button {
    width: auto;
  }
  font-family: $body-font-family;
  padding: rem-calc(50px);
  .orange-circle {
    display: inline-table;
    border-radius: rem-calc(200px);
    height: rem-calc(40px);
    width: rem-calc(40px);
    float: left;
    background: map-get($foundation-palette, primary);
    padding: rem-calc(6px);
    text-align: center;
    color: $white;
    font-size: rem-calc(24px);
    margin-right: rem-calc(20px);
    margin-bottom: rem-calc(30px);
    box-shadow: 0 rem-calc(2px) rem-calc(16px) 0 rgba(235, 93, 11, 0.5);
  }
  .green-circle-container {
    text-align: center;
    width: rem-calc(30px);

    .old {
      background: $dark-gray !important;
    }

    .green-circle {
      border-radius: rem-calc(200px);
      height: rem-calc(24px);
      width: rem-calc(24px);
      background: $interdark-gray;
      padding: rem-calc(4px);
      color: $white;
      font-size: rem-calc(14px);
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }

    .green-circle.active {
      background: map-get($foundation-palette, secondary) !important;
      box-shadow: 0 rem-calc(4px) rem-calc(16px) 0 rgba(0, 62, 171, 0.5);
    }
  }
  .circle-separator {
    width: rem-calc(80px);
    margin: auto;

    hr {
      border-top: rem-calc(1px) solid $interdark-gray;
      border-bottom: 0px none transparent;
      width: 100%;
    }
  }
  .line-separator {
    border: none;
    border-top: rem-calc(1px) solid $interdark-gray;
  }
  .uc {
    text-transform: uppercase;
  }
  ul.ltn {
    list-style-type: none;
  }
  .cart-grid {
    display: grid;
    grid-auto-rows: 100%;
    grid-template-columns: repeat(2, 50%);
    @include breakpoint(medium down) {
      grid-template-columns: repeat(1, 100%);
    }

    #empty-cart-left-column {
      border-right: rem-calc(1px) solid $light-gray;
      @include breakpoint(medium down) {
        display: none;
        visibility: hidden;
        border: 0px;
      }
    }

    #empty-cart-right-column {
      border-left: rem-calc(1px) solid $light-gray;
      @include breakpoint(medium down) {
        border: 0px;
        .sad-cart-image {
          width: auto;
          height: rem-calc(100px);
        }
      }

      .content {
        margin: auto;
      }
    }

    #empty-cart-product-advise-list {
      list-style-type: none;
      margin: 0px;
      padding: 0px;

      li {
        margin: 0px;
        padding: 0px;
      }
    }

    .product-advise-item {
      height: rem-calc(120px);
      overflow: hidden;
      box-shadow: 0 rem-calc(4px) rem-calc(16px) 0 rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      margin-bottom: rem-calc(16px);

      .image {
        width: rem-calc(120px);
      }

      .body {
        justify-content: space-between;
        text-align: left;
        margin: rem-calc(20px);
        width: auto;

        .title {
          font-weight: 700;
          font-size: rem-calc(18px);
        }

        .subtitle {
          font-size: rem-calc(14px);
        }

        .cta {
          color: map-get($foundation-palette, secondary);
          align-items: flex-end;
          height: rem-calc(30px);
          display: flex;
        }
      }
    }
  }
  .sad-cart-image {
    width: rem-calc(320px);
  }
  .medium-typo {
    p {
      font-size: rem-calc(16px);
    }

    h5.no-pad {
      padding: 0px;
    }

    h5.no-mar {
      margin: 0px;
    }
  }
  #empty-cart-list {
    margin-left: 0px;
    padding-left: 0px;

    li {
      display: flex;
      margin-left: 0px;
      margin-bottom: rem-calc(20px);
    }
  }
  #order-items-list {
    width: 100%;
    @include breakpoint(medium down) {
      .change-shipping-address {
        padding-top: rem-calc(10px);
        padding-bottom: rem-calc(10px);
      }
      .change-shipping-type {
        padding-top: rem-calc(10px);
        padding-bottom: rem-calc(10px);
      }
    }

    .order-items-table {
      border-radius: 20px;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1); // fake border with radiud
      border: none;
      thead {
        border-bottom: 1px solid #dbdbdb;
        color: #9b9b9b;
      }

      tbody {
        @include breakpoint(medium down) {
          &:not(:first-of-type) {
            border-top: 1px solid #dbdbdb;
          }
        }
      }
    }
  }
  .circle-preview-thumbnail {
    position: relative;
    border-radius: rem-calc(20px);
    height: rem-calc(24px);
    width: rem-calc(24px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .badge-giftbox {
      position: absolute;
      width: 60%;
      right: rem-calc(-5px);
      bottom: rem-calc(-3px);
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        img {
          max-height: rem-calc(35px);
        }
      }
    }
  }
  .order-shipping-method {
    background-color: $white;
  }
  .order-shipping-method,
  .order-coupon,
  .order-shipping-price,
  .order-items-table {
    font-size: rem-calc(14px);

    .title {
      display: block;
      font-weight: 700;
      font-size: rem-calc(14px);
      margin: 0 0 rem-calc(2px) 0;
    }

    .pad-10 {
      padding-top: rem-calc(10px);
      padding-bottom: rem-calc(10px);
    }

    .price-row {
      display: grid;
      grid-template-columns: rem-calc(50px) rem-calc(50px) rem-calc(50px);
      grid-column-gap: rem-calc(5px);
      align-items: center;
    }

    .remove-item,
    .edit-item {
      cursor: pointer;
      display: flex;
      max-width: rem-calc(100px);
    }

    @include tbodySizeDefault;

    .sub-row {
      display: flex;
      justify-content: space-between;
      color: map-get($foundation-palette, secondary);

      .actions {
        display: flex;
        gap: rem-calc(16px);
      }
    }

    width: 100%;
    margin-bottom: rem-calc(20px);

    .divider {
      display: none;
      border-top: rem-calc(1px) solid $interdark-gray;
      height: rem-calc(1px);
      margin: 0px;
      padding: 0px;
      opacity: 0.5;
    }

    border-collapse: collapse;
    //border-bottom: rem-calc(1px) solid $interdark-gray;
    border-radius: rem-calc(10px);

    .text-left {
      text-align: left;
    }
  }

  div#order-summary {
    @include breakpoint(large up) {
      width: rem-calc(259px);
    }
  }

  #order-summary {
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
    @include breakpoint(medium down) {
      position: fixed;
      bottom: 0px;
      left: 0px;
      border-radius: 0 0 0 0;
      margin: 0px;
      width: 100%;
      z-index: 100;
    }
    .total-price {
      margin-top: rem-calc(10px);
      margin-bottom: rem-calc(10px);
    }
    .promcode-text-container {
      display: grid;
      grid-template-columns: repeat(2, auto);
      margin-top: rem-calc(10px);
      margin-bottom: rem-calc(30px);
      .promocode-text {
        font-size: rem-calc(12px);
        margin-left: rem-calc(5px);
        margin-top: auto;
        margin-bottom: auto;
        background-color: $white;
        color: #757575;
        display: flex;
        height: rem-calc(104px);
        width: rem-calc(203px);
        margin: auto;
        align-items: center;
        @include breakpoint(medium down) {
          background-color: #f8f8f8;
          color: #757575;
          display: flex;
          height: auto;
          width: 100%;
          margin: auto;
          align-items: center;
          flex-direction: row;
        }
      }
    }

    .pad-thumbnail {
      padding: rem-calc(0 13px);
      @include breakpoint(medium down) {
        padding: 0;
        padding-right: rem-calc(16px);
      }
    }

    button {
      width: 100%;
      @include breakpoint(medium up) {
        margin-bottom: rem-calc(10px) !important;
      }
    }

    height: fit-content;
    @include breakpoint(medium down) {
      height: auto;
    }
    margin-left: rem-calc(20px);
    padding: rem-calc(15px);
    width: rem-calc(600px);
    border-radius: rem-calc(24px);
    background-color: $light-gray;
  }
  .acceptConditionContainer {
    padding-top: rem-calc(14px);
    padding-bottom: rem-calc(14px);
    text-align: left !important;
    font-size: rem-calc(14px);

    .acceptConditionCheckbox {
      display: inline-block;
      width: rem-calc(20px);
      height: rem-calc(20px);
      margin: rem-calc(0px);
      padding-left: rem-calc(4px) !important;
      border-radius: rem-calc(3px);
      border: solid rem-calc(1px) #999999;
      background-color: #ffffff;

      &.error {
        border: solid rem-calc(1px) #ff0010 !important;
      }

      &.checked {
        border: solid rem-calc(1px) map-get($foundation-palette, secondary) !important;
        background-color: map-get($foundation-palette, secondary) !important;
      }
    }

    @include breakpoint(medium down) {
      .acceptConditionCheckbox {
        float: none;
        padding: rem-calc(0px);
      }
      .dis-none {
        display: none;
      }
    }

    .acceptConditionLabel {
      font-size: rem-calc(14px);
      @include breakpoint(medium down) {
        .dis-none {
          display: none;
        }
      }

      &.error {
        color: #ff0010;
      }
    }

    a {
      cursor: pointer;
      margin-left: rem-calc(6px);
    }

    @include breakpoint(medium down) {
      text-align: center;
    }
  }
  .mobile-total {
    font-size: rem-calc(14px);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: $black;
  }
  .mobile-total-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @include breakpoint(medium down) {
      border-top: rem-calc(1px) solid $interdark-gray;
    }
  }
  .tax-included-mobile {
    font-size: rem-calc(12px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.4);
  }
  .font-size-promcode {
    font-size: rem-calc(13px);
  }
  .giftabel {
    font-size: rem-calc(11px);
    position: relative;
    top: rem-calc(3px);
    left: rem-calc(5px);
    @include breakpoint(medium down) {
      .dis-none {
        display: none;
      }
    }
  }
  .giftConditionCheckbox {
    display: inline-block;
    width: rem-calc(12px);
    height: rem-calc(12px);
    margin: rem-calc(0px);
    margin-top: rem-calc(3px);
    margin-right: rem-calc(5px);
  }
  @include breakpoint(medium down) {
    .giftConditionCheckbox {
      float: none;
    }
    .dis-none {
      display: none;
    }
  }
  .pencil {
    margin-left: rem-calc(1px);
    margin-right: rem-calc(5px);
  }
  .bin {
    margin-left: rem-calc(1px);
    margin-right: rem-calc(5px);
  }
  tr.border-bottom {
    border-bottom: solid 1px #dbdbdb;
  }
  tr.border-bottom:last-child {
    border: none;
  }
  table.order-items-table {
    border-collapse: collapse;
  }
  .thead-text {
    font-size: rem-calc(14px);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #999999;
  }
  .title-style-row {
    font-size: rem-calc(14px);
    font-weight: bold;
  }
  .bg-coupon {
    border-bottom: none;
    border-radius: rem-calc(20px);
    background-color: #ffefe5;
  }
  td.coupon-formatted {
    font-size: rem-calc(14px);
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: rem-calc(24px);
    letter-spacing: normal;
    text-align: left;
    display: flex;
    gap: 0.4rem;
    align-items: center;
  }
  .coupon-name {
    color: #eb5d0b;
    font-size: rem-calc(14px);
  }
  .shadow-formatted-table {
    border-radius: rem-calc(10px);
    box-shadow: 0 rem-calc(4px) rem-calc(16px) 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border-bottom: none;
  }
  td.pad-right-td {
    overflow: hidden !important;
    white-space: nowrap !important;
    padding-right: rem-calc(51px) !important;
  }
  .text-left-mobile {
    @include breakpoint(medium down) {
      font-size: 14px;
      text-align: left;
    }
    @include breakpoint(small down) {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
  button.phs-button.secondary.hollow.add-products {
    background-color: $white;
  }
  button.circle {
    font-weight: 800;
  }

  button.circle.minus {
    font-size: rem-calc(20px);
  }
  .gray-label {
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #999999;
  }

  .price-container {
    display: flex;
    flex-direction: column;
    gap: rem-calc(6px);

    .price-different-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  .full-total-price {
    text-decoration: line-through;
    margin-right: rem-calc(5px);
    font-size: rem-calc(12px);
    @include breakpoint(medium down) {
      order: 1;
      margin: 0;
    }
  }
  .discount-percentage {
    padding: rem-calc(2px) rem-calc(5px);
    text-align: center;
    border-radius: rem-calc(3px);
    background-color: rgba(252, 169, 114, 0.2);
    font-size: rem-calc(10px);
    color: map-get($foundation-palette, primary);
    align-self: flex-start;
    @include breakpoint(medium down) {
      order: 3;
      margin-right: 0;
    }
  }
  .full-price {
    color: map-get($foundation-palette, primary);
    @include breakpoint(medium down) {
      order: 2;
      text-align: center;
    }
  }

  td.text-left-quantity {
    text-align: left !important;
  }
  @include breakpoint(medium down) {
    .sub-row.display-flex-align {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: baseline;
      margin-left: rem-calc(10px);
    }
  }
  th.text-left.thead-text.text-a-center {
    text-align: center;
  }
  .error-container {
    color: red;
  }
}

.acceptConditionContainer {
  padding-top: rem-calc(14px);
  padding-bottom: rem-calc(14px);
  text-align: left !important;
  font-size: rem-calc(14px);

  .acceptConditionCheckbox {
    display: inline-block;
    width: rem-calc(20px);
    height: rem-calc(20px);
    margin: rem-calc(0px);
    padding-left: rem-calc(4px) !important;
    border-radius: rem-calc(3px);
    border: solid rem-calc(1px) #999999;
    background-color: #ffffff;

    &.error {
      border: solid rem-calc(1px) #ff0010 !important;
    }

    &.checked {
      border: solid rem-calc(1px) map-get($foundation-palette, secondary) !important;
      background-color: map-get($foundation-palette, secondary) !important;
    }
  }

  @include breakpoint(medium down) {
    .acceptConditionCheckbox {
      float: none;
      padding: rem-calc(0px);
    }
    .dis-none {
      display: none;
    }
  }

  .acceptConditionLabel {
    font-size: rem-calc(14px);
    color: #000;
    @include breakpoint(medium down) {
      .dis-none {
        display: none;
      }
    }

    &.error {
      color: #ff0010;
    }
  }

  a {
    cursor: pointer;
    margin-left: rem-calc(6px);
  }

  @include breakpoint(medium down) {
    text-align: center;
  }
}

.billing-info-box {
  display: flex;
  align-items: flex-start;
  gap: rem-calc(8px);
  font-size: rem-calc(14px);

  .billing-info {
    display: flex;
    flex-direction: column;
    gap: rem-calc(4px);
  }

  &__separator {
    color: white;
    margin: rem-calc(16px) 0;
    border-style: solid;
  }
}

.request-invoice-banner {
  display: flex;
  gap: rem-calc(8px);
  margin: rem-calc(20px) 0;
  background-color: $light-gray;
  padding: rem-calc(16px) rem-calc(24px);
  border-radius: rem-calc(16px);
  width: fit-content;
  text-align: start;

  &__description {
    display: flex;
  }
}
