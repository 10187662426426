@mixin ShareWithFriends {
  #share-with-friends {
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: repeat(2, 50%);

    @include breakpoint(medium down) {
      grid-template-columns: repeat(1, 100%);
    }

    #left-column {
      .image-logo {
        // height: rem-calc(500px);
      }
    }

    #right-column {
      margin-top: auto;
      display: grid;

      .top-title {
        margin-top: auto;
      }

      img {
        position: relative;
        right: rem-calc(20px);
        bottom: rem-calc(10px);
      }

      div.sub-title {
        font-weight: normal;
      }

      .gift-code-container {
        text-align: center;
        padding: rem-calc(10px);
        margin-bottom: rem-calc(30px);

        .gift-code {
          border-radius: rem-calc(20px);
          margin: auto;
          // border: 1px dashed $interdark-gray;
          padding: rem-calc(15px);
          width: rem-calc(295px);
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          background-image: linear-gradient(to right, $interdark-gray 50%, white 50%);
          background-position: top;
          background-size: rem-calc(10px 0px);
          background-repeat: repeat-x;
          border-color: $interdark-gray;
          border-width: rem-calc(2px);
          border-style: dashed;

          @include breakpoint(medium down) {
            height: rem-calc(70px);
          }

          .code {
            margin: rem-calc(20px);
            color: map-get($foundation-palette, primary);
            font-size: rem-calc(18px);
            line-height: 1.22;
            letter-spacing: rem-calc(1.8px);
            text-align: center;
          }
        }
      }

      @include breakpoint(medium down) {
        .image-logo {
          max-height: rem-calc(300px);
        }
      }
    }

    .button-container {
      padding-top: rem-calc(10px);
      padding-bottom: rem-calc(10px);

      .hollow {
        background-color: $white;
      }

      @include breakpoint(medium down) {
        position: fixed;
        bottom: 0px;
        left: 0px;
        border-radius: 0 0 0 0;
        margin: 0px;
        width: 100%;
        z-index: 100;
        background-color: $light-gray;
      }
    }

    .ft-sub-formatted {
      font-size: rem-calc(14px);
      margin-bottom: rem-calc(48px);
    }

    .top-title-formatted {
      font-size: rem-calc(27px);
      font-weight: 600;
      margin: rem-calc(13px);
    }

    .copy-button {
      font-size: rem-calc(14px);
      font-weight: bold;
      line-height: 1.21;
      margin-bottom: 0;
    }

    .share-button {
      width: rem-calc(224px);
      height: rem-calc(50px);
      font-size: rem-calc(14px);
      font-weight: bold;
      line-height: 1.21;

      @include breakpoint(medium down) {
        width: rem-calc(330px);
      }
    }
  }
}