@mixin CouponCodeComponent {
  #coupon-code-input-container {
    margin-top: rem-calc(16px);
    @include breakpoint(medium down) {
      display: flex;
      margin-top: 0;
    }
    // #input-area {
    //   width: 100%;
    //   @include breakpoint(large up) {
    //     width: rem-calc(298px);
    //   }
    // }
    #button-area {
      display: contents;
      width: rem-calc(100px);
      margin: rem-calc(0px);
    }
    button[name='reset-coupon-code'] {
      border-radius: rem-calc(20px);
      background-color: map-get($foundation-palette, secondary);
      color: $white;
      padding: rem-calc(5px);
      width: rem-calc(20px);
      height: rem-calc(20px);
      margin-left: rem-calc(-28px);
      margin-top: rem-calc(10px);
      margin-right: rem-calc(8px);
      cursor: pointer;
      font-size: rem-calc(12px);
      position: relative;
      display: none;
    }
    input[name='coupon-code'] {
      @include breakpoint(medium down) {
        border-radius: rem-calc(50px);
      }
      display: inline-block;
      width: 100%;
      border: none;
      background-color: $white;
      outline: none;
      border-radius: rem-calc(10px);
      padding: rem-calc(10px);
      margin-top: 0px;
      font-size: rem-calc(11px);
      border: 1px solid #a4a4a7;

      @include breakpoint(medium up) {
        padding: rem-calc(12px) rem-calc(16px);
        font-size: rem-calc(16px);
        color: #eb5d0b;
        font-weight: 700;
      }
    }
    .couponCodeTextExits {
      button[name='reset-coupon-code'] {
        display: block;
      }
    }
    .isCurrentCoupon {
      input[name='coupon-code'] {
        @include breakpoint(medium down) {
          border-radius: rem-calc(50px) rem-calc(50px) rem-calc(50px) rem-calc(50px);
        }
        color: map-get($foundation-palette, primary);
        border-color: $interdark-gray;
        pointer-events: none;
      }
      button[name='apply-coupon-code'] {
        display: none;
      }
      button[name='reset-coupon-code'] {
        display: block;
      }
    }

    button[name='apply-coupon-code'] {
      background-color: transparent;
      color: map-get($foundation-palette, secondary);
      padding: rem-calc(10px);
      width: rem-calc(65px);
      height: 100%;
      background-color: transparent;
      font-size: rem-calc(13px);
      font-weight: 800;
      border-top-right-radius: rem-calc(10px);
      border-bottom-right-radius: rem-calc(10px);
      position: absolute;
      right: 0;
    }
    input[name='coupon-code']::-webkit-input-placeholder {
      font-size: rem-calc(12px);
      color: $black;

      @include breakpoint(medium up) {
        font-size: rem-calc(14px);
        color: #7d7d82;
        font-weight: 700;
      }
    }
  }

  .coupon_code_message {
    color: red;
    font-size: rem-calc(12px);
    margin-top: rem-calc(16px);

    @include breakpoint(medium up) {
      font-size: rem-calc(14px);
    }

    &__error {
      color: red;
    }

    &__applied {
      color: #25a900;
      font-weight: 700;
      display: flex;
      align-items: center;
      gap: rem-calc(8px);
    }
  }

  #coupon-code-input-container.noCouponCode {
    input[name='coupon-code'] {
      @include breakpoint(medium down) {
        border-radius: rem-calc(50px) rem-calc(50px) rem-calc(50px) rem-calc(50px);
      }
    }
    button[name='apply-coupon-code'] {
      display: none;
    }
    #input-area {
      width: 100%;
    }
    #button-area {
      display: none;
    }
  }
  .accepted-coupon {
    margin: auto;
    width: 100%;
    padding: rem-calc(16px);
    max-width: rem-calc(384px);

    h3 {
      font-size: rem-calc(24px);
      margin-bottom: rem-calc(16px);
    }

    p {
      font-size: rem-calc(16px);
      line-height: rem-calc(24px);
    }
  }
  .denied-coupon {
    margin: auto;
    max-width: rem-calc(384px);
  }
  .denied-coupon img {
    position: relative;
  }
  .denied-coupon h3 {
    margin-bottom: 0;
  }
  .denied-coupon p {
    text-align: center;
    padding: 0 1rem;
    line-height: 1.6;
  }

  .denied-coupon button {
    width: 100%;
    margin: 0.5rem 0 !important;
  }

  div#input-area {
    display: flex !important;
    width: 100%;
    position: relative;
  }
  .flex-space {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .font-size-quantity {
    font-size: rem-calc(14px);
  }
  .font-size-price {
    font-size: rem-calc(20px);
  }
}
