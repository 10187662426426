@import '../phs-config';
@import 'node_modules/foundation-sites/scss/util/util';

@mixin phs-footer {
  .phs-footer {
    .footer-badges-desktop {
      @include breakpoint(medium down) {
        display: none !important;
      }
    }

    padding: rem-calc(37px) 0 rem-calc(67px) 0;
    color: $dark-gray;

    @include breakpoint(large) {
      padding: rem-calc(45px) 0 rem-calc(75px) 0;
    }

    @include breakpoint(xxlarge) {
      padding: rem-calc(65px) 0 rem-calc(105px) 0;
    }

    & a {
      color: $dark-gray !important;

      &::before {
        background: $dark-gray !important;
      }
    }

    & .phs-footer__acc {
      position: relative;
      padding: rem-calc(20px) 0;
      transition: all 0.5s ease-in-out;
      border-top: rem-calc(1px) solid $interdark-gray;
      border-bottom: rem-calc(1px) solid $interdark-gray;

      @include breakpoint(large) {
        border: 0;
        padding: initial;
      }

      &:first-of-type {
        border-top: 0;

        @include breakpoint(medium only) {
          border-top: rem-calc(1px) solid $interdark-gray;
        }
      }

      &:nth-child(odd) {
        border-bottom: 0;

        @include breakpoint(medium only) {
          border-bottom: rem-calc(1px) solid $interdark-gray;
        }
      }

      & .footer-title {
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          right: 0;
          margin-top: -4.5px;
          width: 9px;
          height: 9px;
          background: url($plusMenuIcon) center no-repeat;
          background-size: contain;

          @include breakpoint(large) {
            content: none;
          }
        }
      }

      & .footer-links {
        overflow: hidden;
        max-height: 0;
        transition: all 0.5s ease-in-out;

        @include breakpoint(large) {
          max-height: initial;
        }
      }

      &.is-open {
        & .footer-title {
          &::after {
            background: url($minusMenuIcon) center no-repeat;
            background-size: contain;
          }
        }

        & .footer-links {
          max-height: 500px;
        }
      }
    }

    & .footer-title {
      font-size: 1rem;
      font-weight: bold;
      color: $black;
      position: relative;
      margin: 0;

      @include breakpoint(large) {
        font-size: rem-calc(14px);
        margin: 0 0 rem-calc(14px) 0;
      }

      @include breakpoint(xxlarge) {
        font-size: rem-calc(19px);
      }

      &.is-in-grid {
        margin: 0;
      }
    }

    & .footer-link {
      font-size: rem-calc(14px);
      margin: 0.8rem 0;

      &:last-of-type {
        margin-bottom: 0;
      }

      @include breakpoint(large) {
        font-size: rem-calc(13px);
        margin: 0.5rem 0;

        &:last-of-type {
          margin-bottom: 0.5rem;
        }
      }

      @include breakpoint(xxlarge) {
        font-size: rem-calc(18px);
      }
    }

    & .phs-footer__delivery,
    & .phs-footer__social {
      margin: rem-calc(5px) 0;
      font-size: rem-calc(14px);

      @include breakpoint(large) {
        font-size: rem-calc(13px);
      }

      @include breakpoint(xxlarge) {
        font-size: rem-calc(18px);
      }

      & span {
        margin-left: rem-calc(8px);
      }
    }

    & .footer_100 {
      font-size: rem-calc(13px);
      text-align: center;

      @include breakpoint(large) {
        font-size: rem-calc(13px);
        text-align: center;
      }

      @include breakpoint(xxlarge) {
        font-size: rem-calc(18px);
      }

      & b {
        font-size: rem-calc(16px);

        @include breakpoint(large) {
          font-size: rem-calc(14px);
        }

        @include breakpoint(xxlarge) {
          font-size: rem-calc(19px);
        }
      }
    }

    & .footer-logo-desc {
      font-size: rem-calc(14px);

      @include breakpoint(large) {
        font-size: rem-calc(11px);
      }

      @include breakpoint(xxlarge) {
        font-size: rem-calc(15px);
      }
    }
  }
  footer.bkg-lightGray.phs-footer {
    @include breakpoint(medium down) {
      display: none !important;
    }

    .footerShippingTypes {
      max-width: rem-calc(150px);;
    }
  }
  .pl-5{
    padding-left: rem-calc(5px);
  }
}
